// Generated by Framer (97d1eee)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kpHTG5vW7"];

const serializationHash = "framer-XN2Cr"

const variantClassNames = {kpHTG5vW7: "framer-v-da9tcz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, KRE46DdiB: text ?? props.KRE46DdiB ?? "NEW"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KRE46DdiB, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kpHTG5vW7", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-da9tcz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kpHTG5vW7"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-bc15ec6c-471d-4ee9-b84b-5f602366afaf, rgb(216, 78, 166))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "8px", "--framer-font-weight": "700", "--framer-letter-spacing": "0.03em", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}>NEW</motion.p></React.Fragment>} className={"framer-1e24re"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"JDuZhwyjO"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={KRE46DdiB} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XN2Cr.framer-c39d2w, .framer-XN2Cr .framer-c39d2w { display: block; }", ".framer-XN2Cr.framer-da9tcz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 4px 6px 4px 6px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-XN2Cr .framer-1e24re { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XN2Cr.framer-da9tcz { gap: 0px; } .framer-XN2Cr.framer-da9tcz > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-XN2Cr.framer-da9tcz > :first-child { margin-left: 0px; } .framer-XN2Cr.framer-da9tcz > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"KRE46DdiB":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerszLu1wuIc: React.ComponentType<Props> = withCSS(Component, css, "framer-XN2Cr") as typeof Component;
export default FramerszLu1wuIc;

FramerszLu1wuIc.displayName = "AI Kit/Badge Small";

FramerszLu1wuIc.defaultProps = {height: 16, width: 32};

addPropertyControls(FramerszLu1wuIc, {KRE46DdiB: {defaultValue: "NEW", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerszLu1wuIc, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})